import React from 'react';

export default function Media() {

    return(
        <>
        <h2>Media</h2>
        <div className='video '>
            <iframe className='border shadow' src="https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:6742854464757080064?compact=1" height="284" width="504" frameborder="0" allowfullscreen="" title="Embedded post"></iframe>
        </div>
        </>
    )
}